import Vue from 'vue';
import api from '@/utils/api';
import { API_INITIATION } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    initiations: {docs:[]}
};

const mutations = {
    "SET_INITIATIONS": (state, payload) => {
        Vue.set(state, 'initiations', payload);
    }
};

const actions = {
    async getInitiations(context, data) {
        try {
            const response = await api.apiGetAuth(API_INITIATION.CREATE_RETRIEVE, data);
            context.commit('SET_INITIATIONS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createInitiation(context, data) {
        try {
            const response = await api.apiPostAuth(API_INITIATION.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateInitiation(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_INITIATION.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteInitiation(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_INITIATION.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadWorkerRequestTemplate(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_INITIATION.DOWNLOAD_WORKER_REQUEST_TEMPLATE(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async uploadWorkerRequestTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_INITIATION.UPLOAD_WORKER_REQUEST_TEMPLATE(data.get('company_id')), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {
    initiations: state => {
        return state.initiations;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
