import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_PROPERTY_ROLE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    property_role_settings: {docs:[]}
};

const mutations = {
    "SET_PROPERTY_ROLES": (state, payload) => {
        Vue.set(state, 'property_role_settings', payload);
    }
};

const actions = {
    async getPropertyRoleSetting(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY_ROLE.CREATE_RETRIEVE, data);
            context.commit('SET_PROPERTY_ROLES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPropertyRoleSettingNonPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY_ROLE.LIST_NON_PAGINATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createPropertyRoleSetting(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_PROPERTY_ROLE.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updatePropertyRoleSetting(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_PROPERTY_ROLE.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deletePropertyRoleSetting(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_PROPERTY_ROLE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadSalarySetupTemplate(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY_ROLE.DOWNLOAD_SALARY_SETUP_TEMPLATE(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async uploadSalarySetupTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_PROPERTY_ROLE.UPLOAD_SALARY_SETUP_TEMPLATE(data.get('company_id')), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {
    property_role_settings: state => {
        return state.property_role_settings;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
